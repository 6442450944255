import React, { useState, useEffect, useContext } from 'react';

// Firebase
import { storage } from '../../config/firebase';
import { ref, uploadBytes, listAll, getDownloadURL, deleteObject } from 'firebase/storage';

// uuid
import { v4 } from 'uuid';

// Context
import { authContext } from '../Context/AuthContext';

const Manage = () => {

  const [imgUpload, setImgUpload] = useState();
  const [imgList, setImgList] = useState([]);

  const ctx = useContext(authContext);

  const imageListRef = ref(storage, "/");

  useEffect(() => {
    listAll(imageListRef).then((resp => {
      resp.items.forEach((img) => {
        getDownloadURL(img).then((url) => {
          setImgList((prev) => [...prev, url]);
        });
      });
    }));
  }, []);

  const uploadImage = (e) => {
    e.preventDefault();

    if(imgUpload == null) return;

    const imageRef = ref(storage, imgUpload.name + v4());

    uploadBytes(imageRef, imgUpload).then((snapshot) => {
      alert("Téléversement effectué avec succès");
      getDownloadURL(snapshot.ref).then((url) => {
        setImgList((prev) => [...prev, url]);
      });
      setImgUpload(null);
    });
  };

  const deleteHandler = (url) => {

    const deletedimg = ref(storage, url);

    deleteObject(deletedimg);

    window.location.reload();
  };

  return (
    <div>
      <form onSubmit={uploadImage} className="relative bg-gray-300 w-1/2 mx-auto p-6 m-8 rounded-md flex flex-col items-center">
        <h1 className="font-bold text-2xl">Ajouter une image <span className='absolute right-6 text-base bg-red-500 hover:bg-red-400 duration-100 text-white rounded-md p-2'><button onClick={ctx.logOut}>Déconnexion</button></span></h1>
        <input type="file" onChange={(e) => setImgUpload(e.target.files[0])} className="my-6 self-center"/>
        <button type='submit' className='bg-green-600 hover:bg-green-500 p-2 rounded-md text-white font-bold duration-100 w-1/2'>Ajouter l'image</button>
      </form>
      <div className='flex flex-wrap w-3/4 mx-auto grow '>
        {imgList.map((url) =>
        <div className='w-[250px] m-4 bg-gray-300 p-2 rounded-md' key={url}>
            <img src={url} alt="image"  className='h-[250px]'/>
            <button onClick={() => deleteHandler(url)} className='mt-4 bg-red-500 rounded-md p-2 text-white hover:bg-red-300 duration-100'>Supprimer</button>
        </div>

        )}
        
      </div>
    </div>
  )
}

export default Manage;