import React, { useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';

import { BsFillArrowRightSquareFill, BsFillArrowLeftSquareFill } from 'react-icons/bs';

// Firebase
import { storage } from '../config/firebase';
import { ref, listAll, getDownloadURL } from 'firebase/storage';

const Realisations = ({langue}) => {

    const [activeIndex, setActiveIndex] = useState(0);
    const [imgList, setImgList] = useState([]);

    const imageListRef = ref(storage, "/");

    useEffect(() => {
        listAll(imageListRef).then((resp => {
          resp.items.forEach((img) => {
            getDownloadURL(img).then((url) => {
              setImgList((prev) => [...prev, url]);
            });
          });
        }));
      }, []);

    const updateIndex = (newIndex) => {
        if (newIndex < 0 ) newIndex = imgList.length - 1;
        else if(newIndex === imgList.length) newIndex = 0;

        setActiveIndex(newIndex);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            updateIndex(activeIndex + 1);
        }, 4500);

        return () => {
            if (interval) {
                clearInterval(interval);
            };
        };
    });

    const styles = { 
        transform: `translateX(-${activeIndex * 100}%)`,
    };

    const handlers = useSwipeable({
        onSwipedLeft: () => updateIndex(activeIndex + 1),
        onSwipedRight: () => updateIndex(activeIndex - 1),
    })

  return (
    <div className='Realisations bg-gradient-to-b from-gray-800 to-black'>
         <div className='text-center py-8'>
            <h2 className='text-4xl font-bold text-white'>{langue}</h2>
        </div>
        <div {...handlers} className='carousel overflow-hidden w-3/4 mx-auto relative'>
            <div className='whitespace-nowrap transition-transform duration-300' style={styles}>
                {
                    imgList.map((url) => 
                    <div className='item w-full inline-flex items-center' key={url}>
                        <img src={url} alt="realisation" className='w-screen h-[300px] md:h-[800px] object-cover rounded-lg' />
                    </div>
                    )
                }
            </div>
            <div className='bg-gray-800 rounded-md p-4 text-center flex justify-center absolute left-1/2 -translate-x-1/2 bottom-4 w-3/8 bg-opacity-80  md:text-sm text-white'>
                <button className='hover:text-green-500 duration-100'  onClick={() => updateIndex( activeIndex - 1)}><BsFillArrowLeftSquareFill/></button>
                <p className='px-4'>{activeIndex + 1} / {imgList.length}</p>
                <button className='hover:text-green-500 duration-100' onClick={() => updateIndex( activeIndex + 1)}><BsFillArrowRightSquareFill/></button>
            </div>
        </div>
    </div>
  )
}

export default Realisations