import React, { useState } from "react";

// Components
import Home from "../Home";
import Realisations from "../Realisations";
import Contact from "../Contact";
import Commentaires from "../Commentaires";

const Main = () => {

    const FR = {
        langue: "FR",
        titre: "2 artistes-paysagistes,",
        titreBr: 'une compagnie,',
        titreNature: "votre espace extérieur",
        hookBeg: "Créations Taraxacum est né de la rencontre de deux paysagistes passionnés Alex Néron et Guillaume Leblanc tout deux ayant déjà leurs propres entreprises de paysagement respectives. Ils partagent la même vision de l'art de la création extérieure et souhaitent redonner les lettres de noblesse au métier de paysagiste. Ils sont attentionnés, soucieux du détail et partagent avec vous leurs idées selon votre rêve, votre budget et votre espace extérieur. ",
        hookEnd:"L'équipe de Créations Taraxacum est composée de professionnels diplômés et offre un service de qualité, plus humain et plus personnalisé et ce, selon vos attentes, vos goûts et votre budget. Le territoire s'étant de Montréal à Mont-Tremblant. Que ce soit pour le projet d'une terrasse en pierre naturelles ou en pavé unis, de murets, de bassins d'eau ou de créations de plates-bandes ou simplement d'entretien de vos végétaux l'équipe de Créations Taraxacum relèvera le défi avec brio. Avant tout, ces deux passionnés prendront le temps de vous rencontrer pour créer votre coin de paradis tant souhaité.",
        realisations: "Nos réalisations",
        contact: "Contactez-nous",
        nom: "Votre nom",
        email: "Votre adresse courriel",
        message: "Votre message",
        telephone: "Votre numéro de téléphone",
        envoyer: "Envoyer",
        commentsTitle: 'Commentaire de clients / clientes :',
        comment1: 'Nous sommes tellement satisfaits du travail exceptionnel et professionnel des deux artistes Alex , Guillaume et leur équipe.',
        comment1name: 'Marie-Claude Larocque',
        comment1location: 'cliente de Saint-Calixte',
        comment1date: 'Septembre 2022',
        comment2: 'Wow wowwww!!! Merci encore!! Vous êtes une équipe tout simplement géniale! Nous sommes vraiment satisfaits de vos services, vous avez "le savoir, le savoir-faire et le savoir-être" Je vous recommande à tous.',
        comment2name: 'Chantal Beaulieu',
        comment2location: 'cliente de Saint-Sauveur',
        comment2date: 'Octobre 2022',
        partnerTitle: 'Nos partenaires …',
      }

    const EN ={
        langue: "EN",
        titre: "2 landscape artists,",
        titreBr: 'one company,',
        titreNature: "your outdoor space",
        hookBeg: "Créations Taraxacum was born from the meeting of two passionate landscape artists, Alex Néron and Guillaume Leblanc, both having their own respective landscaping companies. They share the same vision of the art of outdoor creation and wish to restore the nobility of the landscaping profession. They are attentive, detail-oriented and share their ideas with you according to your dream, your budget and your outdoor space. ",
        hookEnd: "The Créations Taraxacum team is made up of qualified professionals who offer a quality service, more human and personalized according to your expectations, tastes and budget. The territory extends from Montreal to Mont-Tremblant. Whether it's for the project of a terrace in natural stone or pavers, walls, water features or the creation of flower beds or simply the maintenance of your plants, the Créations Taraxacum team will meet the challenge with brilliance. Above all, these two passionate people will take the time to meet with you to create your long-awaited paradise.",
        realisations: "Our achievements",
        contact: "Contact us",
        nom: "Your name",
        email: "Your email address",
        message: "Your message",
        telephone: "Your phone number",
        envoyer: "Send",
        commentsTitle: 'Client comments:',
        comment1: 'We are so satisfied with the exceptional and professional work of the two artists Alex, Guillaume and their team.',
        comment1name: 'Marie-Claude Larocque',
        comment1location: 'client from Saint-Calixte',
        comment1date: 'September 2022',
        comment2: 'Wow wowwww!!! Thanks again!! You are simply an amazing team! We are really satisfied with your services, you have "the know-how, the skills and the attitude" I recommend you to everyone.',
        comment2name: 'Chantal Beaulieu',
        comment2location: 'client from Saint-Sauveur',
        comment2date: 'October 2022',
        partnerTitle: 'Our partners ...',
    }

    const [langue, setLangue] = useState(FR);

    const setLangueHandler = (l) => {
        if(l === "FR") setLangue(FR);
        else setLangue(EN);
    }
  return (
    <div>
      <header>
        <Home langue={langue} setLangueHandler={setLangueHandler} />
      </header>
      <main>
        <Realisations langue={langue.realisations} />
        <Commentaires langue={langue} />
        <Contact langue={langue} />
      </main>
      <footer className="bg-gradient-to-b from-gray-800 to-black flex flex-col items-center">
        <img
          src="./img/LogoTarax_1_Blanc.svg"
          alt="Logo"
          className="w-36 pt-8"
        />
        <p className="text-gray-500 py-8">® Créations Taraxacum, 2022 </p>
      </footer>
    </div>
  );
};

export default Main;
