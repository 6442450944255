import React, { useContext } from 'react';

// Router
import { RouterProvider, createBrowserRouter, Navigate } from 'react-router-dom';

// Context
import { authContext } from './Components/Context/AuthContext';

// Css
import './App.css';

// Components
import Main from './Components/Main/Main';
import LogIn from './Components/Admin/LogIn';
import Manage from './Components/Admin/Manage';


const App = () => {

    const ctx = useContext(authContext);

    const routes = !ctx.user ? [
        {
            path: "/",
            element: <Main/>,
        },
        {
            path: "/admin",
            element: <LogIn/>,
        },
        {
            path: '/*',
            element:<Navigate to="/"/>,
        },
    ] : [

        {
            path: "/",
            element: <Main/>,
        },
        {
            path: "/admin",
            element: <Manage/>,
        },
        {
            path: '/*',
            element:<Navigate to="/"/>,
        },
    ];

  return (
    <div className="App">
        <RouterProvider router={createBrowserRouter(routes)}/>
    </div>
  );
}

export default App;
