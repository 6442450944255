import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDI78s3tNLfKvkl5WrWft7rMpk8oXUbsGI",
  authDomain: "taraxacum-c0180.firebaseapp.com",
  projectId: "taraxacum-c0180",
  storageBucket: "taraxacum-c0180.appspot.com",
  messagingSenderId: "230605024562",
  appId: "1:230605024562:web:6ae83e57d27015c75d7b2b"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

const storage = getStorage(app);

export { auth, storage };