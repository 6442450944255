import React, { useContext, useState } from 'react';

// Context
import { authContext } from '../Context/AuthContext';

// Router
import { useNavigate } from 'react-router-dom';

const LogIn = () => {

  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();

  const onChangeForm = (e, prop) => {
    setUser((current) => {
      return {
        ...current,
        [prop]: e.target.value,
      };
    });
  };

  const ctx = useContext(authContext);

  const logInHandler = (e) => {
    e.preventDefault();
    ctx.LogIn(user.email, user.password);

    setUser({email: "", password: ""});
    navigate("/admin");
  };

  return (
  <div className="h-full gradient-form bg-gradient-to-b from-black to-gray-800 md:h-screen">
      <div className="flex justify-center items-center flex-wrap h-full g-6 text-gray-800">
          <div className="block bg-black shadow-lg rounded-lg bg-opacity-50">
              <div className="px-4 md:px-0">
                <div className="md:p-12 md:mx-6">
                  <div className="text-center">
                  <img src="./img/LogoTarax_1_Blanc.svg" alt="Logo" className='w-96 m-8'/>
                  </div>
                  <form onSubmit={logInHandler}>
                    <div className="mb-4">
                      <input
                        type="email"
                        className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
                        placeholder="Email"
                        autoComplete='email'
                        value={user.email}
                        onChange={(e) => onChangeForm(e, "email")}
                      />
                    </div>
                    <div className="mb-4">
                      <input
                        type="password"
                        className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"                        
                        placeholder="Mot de passe"
                        autoComplete='current-password'
                        value={user.password}
                        onChange={(e) => onChangeForm(e, "password")}
                      />
                    </div>
                    <div className="text-center pt-1 mb-12 pb-1">
                      <button
                        className="inline-block px-6 py-2.5 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-500 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out w-full mb-3 bg-green-700"
                        type="sumbit"
                        data-mdb-ripple="true"
                        data-mdb-ripple-color="light"
                      >
                        Connexion
                      </button>
                    </div>
                  </form>
                </div>
              </div>
          </div>
      </div>
  </div>
  )
}

export default LogIn;