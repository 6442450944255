import React from 'react';

import { BsFacebook } from 'react-icons/bs';
import { AiOutlinePhone } from 'react-icons/ai';
import { MdEmail } from 'react-icons/md';

const Contact = ({langue}) => {
  return (
    <div className='Contact bg-gradient-to-b from-gray-800 to-gray-800 text-white  py-24 flex flex-col items-center'>
      <h2 className='text-4xl font-bold text-white'>{langue.contact}</h2>
      <form action="https://getform.io/f/6b977b3a-9a53-4589-9fef-718be7604cbf" method='POST' className='flex flex-col w-1/2 lg:w-1/3 pt-8 text-black'>
          <input type="text" name="nom" placeholder={langue.nom} className='rounded-md p-4' />
          <input type="text" name="email" placeholder={langue.email} className='mt-8 mb-4 rounded-md p-4' />
          <input type="text" name="telephone" placeholder={langue.telephone} className='mt-4 mb-8 rounded-md p-4' />
          <textarea name="message" rows="10" cols="10" placeholder={langue.message} className='rounded-md p-4'></textarea>
          <button type="submit" className='m-4 p-4 bg-green-500 hover:bg-green-700 duration-150 rounded-md w-fit self-center '>{langue.envoyer}</button>
      </form>
      <div className='pt-8'>
        <div className='flex flex-col items-center'>
          <a href="https://www.facebook.com/profile.php?id=100083045782999" target="_blank" rel="noreferrer"><BsFacebook size={50}/></a> 
          <a className='py-4' href="https://www.facebook.com/profile.php?id=100083045782999" target="_blank" rel="noreferrer">Visitez notre page Facebook</a>
        </div>
        <div className='flex flex-col items-center py-8'>
          <AiOutlinePhone size={50}/>
          <p className='pb-2 pt-4'>Alex Néron : <a href="tel:+1 514-586-5543">514 586-5543</a></p>
          <p className='pb-4 pt-2'>Guillaume Leblanc : <a href="tel:+1 450-694-2868">450 694-2868</a></p>
        </div>
        <div className='flex flex-col items-center'>
          <MdEmail size={50}/>
          <a href="mailto:info@taraxacum.ca">info@taraxacum.ca</a>
        </div>
      </div>
    </div>
  )
}

export default Contact;