import React from 'react';

const Language = ({setLangueHandler, langue}) => {
  return (
    <div className='Language absolute top-8 right-8 md:right-16 lg:right-32 flex'>
      <div><p className={`hover:text-green-900 duration-100 cursor-pointer ${langue === "FR" ? "text-green-500" : "text-white"}`} onClick={() => setLangueHandler("FR")}>FR</p></div>
      <div className='px-1'>|</div>
      <div><p className={`hover:text-green-900 duration-100 cursor-pointer ${langue === "EN" ? "text-green-500" : "text-white"}`} onClick={() => setLangueHandler("EN")}> EN</p></div>
            
    </div>
  )
}

export default Language;