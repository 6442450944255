import React, { useState, useEffect } from "react";

// Firebase
import {
  signOut,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";

import { auth } from "../../config/firebase";

// Context Auth
const authContext = React.createContext({
  logOut: () => {},
  LogIn: () => {},
  user: {},
  createUser: (email, password) => {},
});

const { Provider } = authContext;

const AuthProvider = ({ children }) => {
  
    const [user, setUser] = useState(null);

    const LogIn = (email, password) => {
    signInWithEmailAndPassword(auth, email, password).then((userCredential) => {
      // Signed in
        setUser(userCredential.user);
        console.log(user);
    });
  };

  const createUser = (email, password) => {
    createUserWithEmailAndPassword(auth, email, password).then(
      (userCredential) => {
        // Signed in
        setUser(userCredential.user);
      }
    );
  };

  const logOut = () => {
    signOut(auth);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
        setUser(currentUser);
    });
    return unsubscribe;
  }, []);

  return <Provider value={{ logOut, createUser, LogIn, user }}>{children}</Provider>;
};

export { authContext, AuthProvider };
