import React from 'react';

// Router
import ReactDOM from 'react-dom/client';

// Context
import { AuthProvider } from './Components/Context/AuthContext';

// Components
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AuthProvider>
      <App />
    </AuthProvider>
);