const Commentaires = ({langue}) => {

  return (
    <div className='Partner bg-gradient-to-b from-black to-gray-800'>
        <div className='text-center py-8'>
            <h2 className='text-4xl font-bold text-white'>{langue.commentsTitle}</h2>
        </div>
        <div className="p-4 flex flex-col justify-center items-center text-white max-w-[860px] mx-auto">
          <div>
              <p className="mb-2 text-lg font-semibold">{langue.comment1}</p>
              <p className="text-gray-300">-{langue.comment2name}, {langue.comment2location}, {langue.comment2date}</p>
          </div>
          <div className="pt-16">
              <p className="mb-2 text-lg font-semibold">{langue.comment2}</p>
              <p className="text-gray-300">-{langue.comment1name}, {langue.comment1location}, {langue.comment1date}</p>
          </div>
        </div>
    </div>
  )
}

export default Commentaires;