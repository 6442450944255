import React from 'react';

// Components
import Language from './Language';

const Home = ({langue, setLangueHandler}) => {
  return (
    <div className='Home bg-gradient-to-b from-black to-gray-800 text-white py-24 md:py-36'>
        <Language setLangueHandler={setLangueHandler} langue={langue.langue}/>
        <div className='flex flex-col items-center md:flex-row md:justify-center px-8'>
            <div className="logo flex items-center md:justify-center md:pr-8">
                <img src="./img/LogoTarax_1_Blanc.svg" alt="Logo" className='w-96'/>
            </div>
            <div className="paragraphe md:w-1/2 pl-8 pt-8">
                <p className='font-bold text-4xl uppercase'>{langue.titre} <br /> {langue.titreBr} <span className='bg-gradient-to-r from-green-500 to-green-600 bg-clip-text text-transparent'>{langue.titreNature}</span></p>

                <p className='py-8 text-gray-300'>{langue.hookBeg}</p>

                <p className='text-gray-300'>{langue.hookEnd}</p>
            </div>
        </div>
    </div>
  )
}

export default Home;